<template>
  <v-card elevation="0" color="#172033" :height="$vuetify.breakpoint.xs ? 300 : '80vh'" class="d-flex justify-center">
    <v-progress-circular class="align-self-center" indeterminate color="green" size="120"></v-progress-circular>
  </v-card>
</template>

<script>
export default {
  name: "MainLoading",
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>
